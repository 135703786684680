import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, TextField, Typography} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// project imports
import NavItem from '../NavItem';
import useConfig from 'hooks/useConfig';

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import axios from "../../../../../utils/axios";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavAeroCollapse = ({ menu, level, proyectoSlug }) => {
  const theme = useTheme();
  const { borderRadius } = useConfig();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [buscar, setBuscar] = useState("");
  const [aerogeneradoresItems, setAerogeneradoresItems] = useState([])
  const [aerogeneradoresItemsFull, setAerogeneradoresItemsFull] = useState([])

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    const params = {
      proyectoSlug: proyectoSlug,
    };
    axios.post("/api/aerogenerador/aerogeneradores-proyecto/", params)
      .then((res)=>{
        const listaAeros = res.data.map((aero)=>{
          return {
            id: aero.nombre,
            title: aero.nombre,
            type: 'item',
            url: '/proyecto/' + proyectoSlug +'/aerogenerador/' + aero.slug + '/detalle',
            breadcrumbs: false
          }
        })
        setBuscar("")
        setAerogeneradoresItems(listaAeros)
        setAerogeneradoresItemsFull(listaAeros)
      })
  }, [pathname, proyectoSlug]);

  const handleChange = (event) => {
    setBuscar(event.target.value);
    setAerogeneradoresItems(aerogeneradoresItemsFull.filter(item=>item.title.toLowerCase().includes(event.target.value.toLowerCase())))
  };

  const handleClearClick = () => {
    setBuscar("")
    setAerogeneradoresItems(aerogeneradoresItemsFull)
  }

  // menu collapse & item
  const menus = aerogeneradoresItems?.map((item) => {
    switch (item.type) {
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} proyectoSlug={proyectoSlug} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${borderRadius}px`,
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {open && (
          <>
            <TextField
              value={buscar}
              onChange={handleChange}
              id="input-search-list-style1"
              placeholder="Buscar"
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    sx={{ visibility: buscar ? "visible" : "hidden" }}
                    onClick={handleClearClick}
                  >
                    <ClearIcon fontSize="small"/>
                  </IconButton>
                ),
              }}
              sx={{
                p: 0,
                pl: "32px",
                pr: "12px",
                "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
              }}
            />
            <List
              component="div"
              disablePadding
              sx={{
                position: 'relative',
                maxHeight: '300px',
                overflow: 'auto',
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  left: '32px',
                  top: 0,
                  height: '100%',
                  width: '1px',
                  opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                  background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                }
              }}
            >
              {menus}
            </List>
          </>
        )}
      </Collapse>
    </>
  );
};

NavAeroCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number
};

export default NavAeroCollapse;
