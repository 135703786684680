import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import {useDispatch, useSelector} from "../../store";
import {getProyectos} from "../../store/slices/user";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthProyectGuard = ({ children }) => {
    const { isLoggedIn, perfil } = useAuth();
    const { proyectoSlug } = useParams();
    const userState = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [showContent, setShowContent] = React.useState(false);

    useEffect( () => {
        if (!isLoggedIn) {
            navigate('/login', {replace: true});
        } else {
            // console.log('logged', userState, location)
            if (userState.gallery !== undefined) {
                if (userState.gallery.find((proyecto) => proyecto.slug === proyectoSlug) === undefined) {
                    console.log("No encontrado")
                    navigate('/', {replace: true})
                } else {
                    if (location.pathname.includes("/configuracion/") && perfil < 3) {
                        console.log("No permitido")
                        navigate('/', {replace: true})
                    } else if (location.pathname.includes("/componentes") && perfil < 3) {
                        console.log("No permitido")
                        navigate('/', {replace: true})
                    } else {
                        setShowContent(true)
                    }
                }
            } else {
                dispatch(getProyectos())
            }
        }
    }, [isLoggedIn, navigate, proyectoSlug, userState.gallery]);

    if (showContent) {
        return children;
    } else {
        return <></>
    }

};

AuthProyectGuard.propTypes = {
    children: PropTypes.node
};

export default AuthProyectGuard;
