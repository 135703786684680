import * as React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

// third-party
import PopupState, { bindToggle } from "material-ui-popup-state";


// assets
import { IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";

import { useDispatch, useSelector } from "store";
import { getProyectos } from "store/slices/user";
import ShowSearchItem from "layout/ShowSearchItems";
import { ClickAwayListener } from '@mui/base';

// styles
/*const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));*/

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "light" ? theme.palette.dark[800] : "#fff",
    },
  })
);

/*const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "light"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "light"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "light"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  })
);*/

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Buscar proyecto"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="1rem"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {/*<HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                    </HeaderAvatarStyle>*/}
          <Box sx={{ ml: 2 }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                background:
                  theme.palette.mode === "light"
                    ? theme.palette.dark.main
                    : theme.palette.orange.light,
                color: theme.palette.orange.dark,
                "&:hover": {
                  background: theme.palette.orange.dark,
                  color: theme.palette.orange.light,
                },
              }}
              {...bindToggle(popupState)}
            >
              <IconX stroke={1.5} size="1.3rem" />
            </Avatar>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [searchValues, setSearchValues] = useState([]);
  const userState = useSelector((state) => state.user);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    dispatch(getProyectos());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchValues(userState.gallery);
  }, [userState]);

  const handleChangeSearch = (event) => {
    // if (event.target.value === "") {
    //   setSearchValues([]);
    //   return;
    // }
    // dispatch(getProyectos());
    const searchProyectos = userState.gallery.filter(
      (p) =>
        p.nombre.toLowerCase().includes(event.target.value.toLowerCase()) ||
        p.cliente.toLowerCase().includes(event.target.value.toLowerCase()) ||
        p.codigo.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSearchValues(searchProyectos);
  };

  const handleClickAway = () => {
    setShowSearch(false);
    setValue('')
  };

  const handleClick = () => {
    setShowSearch(true);
  };

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <OutlineInputStyle
            onClick={handleClick}
            id="input-search-header"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              handleChangeSearch(e);
            }}
            placeholder="Buscar proyecto"
            startAdornment={
              <InputAdornment position="start">
                <IconSearch
                  stroke={1.5}
                  size="1rem"
                  color={theme.palette.grey[500]}
                />
              </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{"aria-label": "weight", autoComplete: 'off'}}
          />
        </ClickAwayListener>
        {showSearch && (
          <ShowSearchItem
            searchValues={searchValues}
            input={value}
          />
        )}
      </Box>
      <Box/>
    </>
  );
};

export default SearchSection;
