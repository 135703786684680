import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  checklistItems: [],
  dataDialogSubseccion: {
    open: false,
    // componenteIdx: 0,
    // seccionIdx: 0,
    // subseccionIdx: 0,
  },
  dataDialogQuestion: {
    open: false,
    clase:'',
    // editData: null,
  },
  dataDialogSeccion: {
    open: false,
    clase: '',
  }
};

const checklist = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    setChecklistItems(state, action) {
      state.checklistItems = action.payload;
    },
    addImagenSeccion(state, action) {
      const { componenteIdx, seccionIdx, image } = action.payload;
      // console.log("addImagenSeccion", componenteIdx, seccionIdx, image, state.checklistItems[componenteIdx].secciones[seccionIdx])
      state.checklistItems[componenteIdx].secciones[seccionIdx].nuevaImagen = image
    },
    // ########################################
    //              Subsections
    // ########################################
    addSubseccion(state, action) {
      const { componenteIdx, seccionIdx, nombreSubseccion } = action.payload;
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones.push({ nombre: nombreSubseccion, questions: [] })
    },
    editSubseccion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, nombreSubseccion } = action.payload;
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].nombre = nombreSubseccion
    },
    changeOrdenSubsecciones(state, action) {
      const { componenteIdx, seccionIdx, subsecciones } = action.payload;
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones = subsecciones
    },
    deleteSubseccion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx } = action.payload;
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones.splice(subseccionIdx, 1)
    },
    toggleDialogAddSubseccion(state, action) {
      state.dataDialogSubseccion = { open: !state.dataDialogSubseccion.open, ...action.payload, editData: null }
    },
    toogleDialogSeccion(state, action) {
      state.dataDialogSeccion = { open: !state.dataDialogSeccion.open, ...action.payload, editData: null }
    },
    toggleDialogEditSubseccion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx } = action.payload;
      const subseccion = state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx]
      const { nombre } = { ...subseccion }
      state.dataDialogSubseccion = { open: !state.dataDialogSubseccion.open, componenteIdx, seccionIdx, subseccionIdx, nombre }
    },
    // ########################################
    //               Questions
    // ########################################
    addQuestion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, question } = action.payload;
      // console.log(componenteIdx, seccionIdx, subseccionIdx, question);
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].questions.push(question)
    },
    editQuestion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, questionIdx, question } = action.payload;
      // console.log( componenteIdx, seccionIdx, subseccionIdx, questionIdx, question );
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].questions[questionIdx] = question
    },
    changeOrdenQuestions(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, questions } = action.payload;
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].questions = questions
    },
    deleteQuestion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, questionIdx } = action.payload;
      console.log(componenteIdx, seccionIdx, subseccionIdx, questionIdx );
      state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].questions.splice(questionIdx, 1)
    },
    toggleDialogAddQuestion(state, action) {
      state.dataDialogQuestion = { open: !state.dataDialogQuestion.open, ...action.payload}
    },
    toggleDialogEditQuestion(state, action) {
      const { componenteIdx, seccionIdx, subseccionIdx, questionIdx, clase } = action.payload;
      const question = state.checklistItems[componenteIdx].secciones[seccionIdx].subsecciones[subseccionIdx].questions[questionIdx]
      state.dataDialogQuestion = { open: !state.dataDialogQuestion.open, componenteIdx: componenteIdx, seccionIdx: seccionIdx, subseccionIdx: subseccionIdx, questionIdx: questionIdx, dataQuestion: question, clase }
    },
  }
});

export default checklist.reducer;

export const {
  setChecklistItems, addSubseccion, editSubseccion, changeOrdenSubsecciones,
  addQuestion, changeOrdenQuestions, deleteSubseccion, deleteQuestion,
  toggleDialogAddSubseccion, toggleDialogEditSubseccion, toggleDialogAddQuestion,
  toggleDialogEditQuestion, editQuestion, toogleDialogSeccion, addImagenSeccion} = checklist.actions;
