/**
 * axios setup to use mock service
 */

import axios from 'axios';
import useAuth from "../hooks/useAuth";

// const axiosServices = axios.create();
const axiosServices = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND_ADDR : 'http://localhost:8000/',
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log('axios', error, error.response)
      if (error.response && error.response.status === 401) {
        const { logout } = useAuth();
        logout()
      }
      return Promise.reject((error.response && error.response.data) || 'Wrong Services')
    }
);

export default axiosServices;
