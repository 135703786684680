// assets
import PreviewIcon from "@mui/icons-material/Preview";
import SettingsIcon from "@mui/icons-material/Settings";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import WindPowerIcon from "@mui/icons-material/WindPower";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SchemaIcon from '@mui/icons-material/Schema';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "proyecto",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/proyecto/:proyectoSlug/dashboard",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "observaciones",
      title: "Observaciones",
      type: "item",
      url: "/proyecto/:proyectoSlug/observaciones",
      icon: PreviewIcon,
      breadcrumbs: false,
    },
    {
      id: "puerto",
      title: "Acopio Puerto",
      type: "collapse",
      icon: DirectionsBoatIcon,
      breadcrumbs: false,
      children: [
        {
          id: "componentes-puerto",
          title: "Componentes",
          // type: 'collapse',
          type: "item",
          // url: '/dashboard/puerto',
          url: "/proyecto/:proyectoSlug/puerto/detalle",
          // icon: EventBusyIcon,
          breadcrumbs: false,
        },
        {
          id: "inspecciones-puerto",
          title: "Inspecciones",
          // type: 'collapse',
          type: "item",
          // url: '/dashboard/puerto',
          url: "/proyecto/:proyectoSlug/puerto/inspecciones",
          // icon: EventBusyIcon,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "patio",
      title: "Acopio Parque",
      type: "collapse",
      icon: WarehouseIcon,
      breadcrumbs: false,
      children: [
        {
          id: "componentes-patio",
          title: "Componentes",
          // type: 'collapse',
          type: "item",
          // url: '/dashboard/puerto',
          url: "/proyecto/:proyectoSlug/patio/detalle",
          // icon: EventBusyIcon,
          breadcrumbs: false,
        },
        {
          id: "inspecciones-patio",
          title: "Inspecciones",
          type: "item",
          // type: 'collapse',
          // url: '/dashboard/patio',
          url: "/proyecto/:proyectoSlug/patio/inspecciones",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "aerogeneradores",
      title: "Aerogeneradores",
      type: "aerogeneradores",
      icon: WindPowerIcon,
    },
    {
      id: "componentes",
      title: "Componentes",
      type: "item",
      url: "/proyecto/:proyectoSlug/componentes",
      icon: SchemaIcon ,
      breadcrumbs: false,
      perfil: 3,
    },
    {
      id: "configuracion",
      title: "Configuración",
      type: "settings",
      url: "/dashboard/analytics",
      icon: SettingsIcon,
      breadcrumbs: false,
      perfil: 3,
      children: [
        {
          id: "configuracion-general",
          title: "General",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/general",
          breadcrumbs: false,
        },
        {
          id: "configuracion-aerogeneradores",
          title: "Aerogeneradores",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/aerogeneradores",
          breadcrumbs: false,
        },
        {
          id: "configuracion-componentes",
          title: "Componentes",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/componentes",
          breadcrumbs: false,
        },
        {
          id: "configuracion-postmontaje",
          title: "Postmontaje",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/postmontaje",
          breadcrumbs: false,
        },
        {
          id: "configuracion-contextos",
          title: "Contextos",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/contextos",
          breadcrumbs: false,
        },
        {
          id: "configuracion-checklist",
          title: "Checklist",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/checklist",
          breadcrumbs: false,
        },
        {
          id: "configuracion-dashboards",
          title: "Dashboard",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/dashboard",
          breadcrumbs: false,
        },
        {
          id: "planificacion-proy",
          title: "Planificación",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/planificacion",
          breadcrumbs: false,
        },
        {
          id: "configuracion-vistas3d",
          title: "Vistas 3D",
          type: "item",
          url: "/proyecto/:proyectoSlug/configuracion/vistas3d",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default dashboard;
