// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 *
 */
// ==============================|| LOGO SVG ||============================== //
import logo from 'assets/images/logo-revergy-1.svg';
const Logo = () => {
    const theme = useTheme();

    return (
        <img src={theme.palette.mode === 'dark' ? logo : logo} alt="Revergy" width="150" />
    );
};

export default Logo;
