import { lazy } from 'react';

// project imports
import ProjectLayout from 'layout/ProjectLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const ProjectGallery = Loadable(lazy(() => import('views/projects/Gallery')));
const Configuracion = Loadable(lazy(() => import('views/configuracion/Configuracion')));
const Checklist = Loadable(lazy(() => import('views/configuracion/checklists/Checklist')));
const GraficosObservacion = Loadable(lazy(() => import('views/project/observaciones/Graficos')));

// ==============================|| MAIN ROUTING ||============================== //

const ProjectRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <ProjectLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <ProjectGallery />
        },
        {
            path: '/graficos',
            element: <GraficosObservacion />
        },
        {
            path: '/configuracion',
            element: <Configuracion />
        },
        {
            path: '/configuracion/checklist/:checklistId',
            element: <Checklist />
        }
    ]
};

export default ProjectRoutes;
