import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, perfil } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        } else {
            // console.log("AuthGuard", perfil, location)
            if (location.pathname.includes("/configuracion") && perfil < 3) {
                console.log("No permitido")
                navigate('/', {replace: true})
            }
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
