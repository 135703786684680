// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  proyectos: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET LISTA DE PROYECTOS
    getProyectosSuccess(state, action) {
      // console.log("setting gallery", action.payload)
      state.gallery = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProyectos() {
  return async () => {
    try {
      const params = {
        opcion: 'get_proyectos'
      }
      const response = await axios.post('/api/', params);
      // console.log("getProyectos::response", response)
      // console.log(response.data)
      await dispatch(slice.actions.getProyectosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
