import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  inspeccion: {},
  componentes: [],
  incidencias: [],
  dataDialogImage: {
    open: false,
    componenteData: {},
  },
  editImage: {
    open: false,
    componenteData: {},
  },
  obsDialog: {
    open: false,
    componenteData: {},
  },
};

const registroFotografico = createSlice({
  name: "registroFotografico",
  initialState,
  reducers: {
    setInspeccionData(state, action) {
      state.inspeccion = action.payload;
      const newComponentes = state.inspeccion.componentes.map((componente) => {
        const { imagenes, ...data } = componente;
        const newImgs = imagenes.map((img) => ({ ...img, load: false }));
        return { ...data, imagenes: newImgs };
      });
      state.componentes = newComponentes;
      state.incidencias = [...action.payload.incidencias];
    },
    setLoad(state, action) {
      const { idx, idxImg } = action.payload;
      const { load } = state.componentes[idx].imagenes[idxImg];
      state.componentes[idx].imagenes[idxImg].load = !load;
    },
    addUploadedImage(state, action) {
      const { idx, imageData } = action.payload;
      state.componentes[idx].imagenes.push(imageData);
    },
    toggleDialogImage(state, action) {
      state.dataDialogImage = {
        open: !state.dataDialogImage.open,
        componenteData: {},
        ...action.payload,
      };
    },
    toggleObsDialog(state, action) {
      state.obsDialog = {
        open: !state.obsDialog.open,
        componenteData: {},
        ...action.payload,
      };
      console.log(state.obsDialog.componenteData);
    },
    setObsDialog(state, action) {
      const { idx, observacion } = action.payload;
      state.componentes[idx].observacion = observacion;
    },
    toggleEditImage(state, action) {
      state.editImage = {
        open: !state.editImage.open,
        componenteData: {},
        ...action.payload,
      };
    },
    updateImg(state, action) {
      const { idx, idxImg, data } = action.payload;
      state.componentes[idx].imagenes[idxImg] = data;
    },
    updateImgList(state, action) {
      const { idx, imgList } = action.payload;
      state.componentes[idx].imagenes = imgList;
    },
    cpDataImg(state, action) {
      const { idx, origen, destino } = action.payload;
      const { descripcion, severidad } =
        state.componentes[idx].imagenes[origen];
      state.componentes[idx].imagenes[destino].descripcion = descripcion;
      state.componentes[idx].imagenes[destino].severidad = severidad;
      action.payload.data = {
        id: state.componentes[idx].imagenes[destino].id,
        descripcion: state.componentes[idx].imagenes[destino].descripcion,
        severidad: state.componentes[idx].imagenes[destino].severidad,
      };
    },
    delImg(state, action) {
      const { idx, imgIdx } = action.payload;
      state.componentes[idx].imagenes.splice(imgIdx, 1);
    },
    addIncidencia(state, action) {
      const newIncidencia = action.payload
      // const { newIncidencia } = action.payload;
      let result = state.incidencias.findIndex(obj => {
        return obj.id === newIncidencia.id
      })
      if (result !== -1) {
        state.incidencias.splice(result, 1, newIncidencia)
      } else {
        state.incidencias.push(action.payload)
      }

    },
    deleteIncidencia(state, action) {
      const incidenciaId = action.payload
      // const { newIncidencia } = action.payload;
      let result = state.incidencias.findIndex(obj => {
        return obj.id === incidenciaId
      })
      state.incidencias.splice(result, 1)
    }
  },
});

export default registroFotografico.reducer;

export const actionsRegFot = registroFotografico.actions;

export const {
  setInspeccionData,
  setLoad,
  toggleDialogImage,
  toggleObsDialog,
  setObsDialog,
  addUploadedImage,
  toggleEditImage,
  updateImg,
  updateImgList,
  cpDataImg,
  delImg,
  addIncidencia,
  deleteIncidencia,
} = registroFotografico.actions;
