import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthProyectGuard from "../utils/route-guard/AuthProyectGuard";
// import DescargaAcopio from 'views/project/configuracion/descargaAcopio';

// dashboard routing
const Observaciones = Loadable(lazy(()=>(import('views/project/observaciones'))))
const Observacion = Loadable(lazy(()=>(import('views/project/observaciones/VerObservacion'))))
const IspeccionesPatioPuerto = Loadable(lazy(()=>(import('views/project/patio-puerto/Inspecciones'))))
const ComponentesPatioPuerto = Loadable(lazy(()=>(import('views/project/patio-puerto'))))
const Inspeccion = Loadable(lazy(() => import('views/project/patio-puerto/Inspeccion')));
const Registro = Loadable(lazy(() => import('views/project/patio-puerto/Registro')));
const ChecklistProyecto = Loadable(lazy(() => import('views/project/configuracion/checklist')));
const ContextoProyecto = Loadable(lazy(() => import('views/project/configuracion/contextos')));
const ComponentesProyecto = Loadable(lazy(() => import('views/project/configuracion/componentes')));
const GeneralProyecto = Loadable(lazy(() => import('views/project/configuracion/general')));
const DashboardsProyecto = Loadable(lazy(() => import('views/project/configuracion/dashboards')));
const Panificacion = Loadable(lazy(() => import('views/project/configuracion/planificacion')));
const AerogeneradoresConfig = Loadable(lazy(() => import('views/project/configuracion/aerogeneradores')));
const Postmontaje = Loadable(lazy(() => import('views/project/configuracion/postmontaje')));
const Vistas3dConfig = Loadable(lazy(() => import('views/project/configuracion/vistas3d')));
const Aerogenerador = Loadable(lazy(() => import('views/project/aerogenerador')));
const Dashboard = Loadable(lazy(() => import('views/project/dashboard')));
const Componentes = Loadable(lazy(() => import('views/project/componentes')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/proyecto/:proyectoSlug/',
    element: (
        <AuthProyectGuard>
            <MainLayout />
        </AuthProyectGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'observaciones',
            element: <Observaciones />
        },
        {
            path: 'observacion/:observacionId/detalle',
            element: <Observacion />
        },
        {
            path: 'patio/inspecciones',
            element: <IspeccionesPatioPuerto />
        },
        {
            path: 'puerto/inspecciones',
            element: <IspeccionesPatioPuerto />
        },
        {
            path: 'patio/detalle',
            element: <ComponentesPatioPuerto />
        },
        {
            path: 'puerto/detalle',
            element: <ComponentesPatioPuerto />
        },
        {
            path: 'inspeccion/editar/:inspeccionId',
            element: <Inspeccion />
        },
        {
            path: 'inspeccion/registro-fotografico/:inspeccionId',
            element: <Registro />
        },
        {
            path: 'configuracion/checklist',
            element: <ChecklistProyecto />
        },
        {
            path: 'configuracion/contextos',
            element: <ContextoProyecto />
        },
        {
            path: 'configuracion/componentes',
            element: <ComponentesProyecto />
        },
        {
            path: 'configuracion/general',
            element: <GeneralProyecto />
        },
        {
            path: 'configuracion/dashboard',
            element: <DashboardsProyecto />
        },
        {
            path: 'configuracion/planificacion',
            element: <Panificacion />
        },
        {
            path: 'configuracion/postmontaje',
            element: <Postmontaje />
        },
        {
            path: 'configuracion/aerogeneradores',
            element: <AerogeneradoresConfig />
        },
        {
            path: 'configuracion/vistas3d',
            element: <Vistas3dConfig />
        },
        {
            path: 'aerogenerador/:aerogeneradorSlug/detalle',
            element: <Aerogenerador />
        },
        {
            path: 'componentes',
            element: <Componentes />
        },
    ]
};

export default MainRoutes;
