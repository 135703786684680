// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import cartReducer from './slices/cart';
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import userReducer from './slices/user';
import checklistReducer from './slices/checklist';
import registroFotograficoReducer from "./slices/registroFotografico";
// import authReducer from './accountReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    // auth: authReducer,
    snackbar: snackbarReducer,
    user: userReducer,
    menu: menuReducer,
    checklist: checklistReducer,
    registroFotografico: registroFotograficoReducer,
});

export default reducer;
