import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { shouldForwardProp } from "@mui/system";
import { baseURL } from "store/constant";
import proyectoImage from "assets/images/proyecto.webp";

import urlJoin from "url-join";

const ListStyle = styled(List, { shouldForwardProp })(({ theme }) => ({
  overflow: "auto",
  width: 434,
  maxHeight: 200,
  marginLeft: 16,
  padding: 0,
  // paddingLeft: 16,
  // paddingRight: 16,
  position: "absolute",
  borderRadius: 4,
  border: "solid #bdbdbd",
  borderTopColor: "transparent",
  "& input": {
    // background: "transparent !important",
    paddingLeft: "4px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background:
      theme.palette.mode === "light" ? theme.palette.dark[800] : "#fff",
  },
}));

const ShowSearchItem = ({ searchValues, input }) => {
  const backProfile = (imagen_ficha) => {
    if (imagen_ficha !== null) {
      // console.log(baseURL, process.env.NODE_ENV, process.env.REACT_APP_BACKEND_ADDR)
      return urlJoin(baseURL, imagen_ficha);
    } else {
      return proyectoImage;
    }
  };

  // if (input.length === 0) {
  //   return null;
  // }
  return searchValues.length > 0 ? (
    <ListStyle
      sx={{
        bgcolor: "background.paper",
      }}
      aria-label="contacts"
    >
      {searchValues.map((el, idx) => (
        <ListItem
          disablePadding
          key={idx}
          secondaryAction={
            <Typography
              sx={{ display: "block" }}
              component="span"
              variant="overline"
              display="block"
              gutterBottom
              color="text.primary"
            >
              {el.codigo}
            </Typography>
          }
          // button
          // component={Link}
        >
          <ListItemButton to={`/proyecto/${el.slug}/dashboard`}>
            <ListItemAvatar>
              <Avatar
                alt={`${el.nombre}-${el.codigo}`}
                src={backProfile(el.imagen_ficha)}
                sx={{ background: "none" }}
                imgProps={{
                  sx: {
                    objectFit:
                      backProfile(el.imagen_ficha) === proyectoImage &&
                      "contain !important",
                  },
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={el.nombre}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    gutterBottom
                  >
                    {el.cliente}
                  </Typography>
                  {/* <Typography
                      sx={{ display: "block" }}
                      component="span"
                      variant="overline"
                      display="block"
                      gutterBottom
                      color="text.primary"
                    >
                      {el.codigo}
                    </Typography> */}
                </React.Fragment>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </ListStyle>
  ) : (
    <ListStyle
      sx={{
        bgcolor: "background.paper",
        p: 2,
      }}
      aria-label="contacts"
    >
      <ListItem disablePadding>
        <ListItemText primary={`Proyecto ${input} no encontrado`} />
      </ListItem>
    </ListStyle>
  );
};

export default ShowSearchItem;
